/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700&display=swap');
// @import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap");
//@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600;700&display=swap");
// @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;400;500;600;700&family=Urbanist:wght@100;200;300;400;500;600;700;800;900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;900&display=swap');
@import "~@ng-select/ng-select/themes/default.theme.css";

* {
  // font-family: 'Open Sans', sans-serif;
  font-family: 'Poppins', sans-serif;
  // font-family: 'Josefin Sans', sans-serif;
  // font-family: 'Urbanist', sans-serif;
  font-weight: 400;
  line-height: 1.7;
  color: var(--secondary-text);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-size: 14px;
  // font-size: 16px;
}

h1,h2,h3,h4,h5,h6 {
  font-family: 'Poppins', sans-serif;
  color: var(--dark1);
}

// body{
//     background: red;
// }

ul li {
  line-height: 32px;
}

// p{
//     margin-bottom: 10px !important;
// }
ion-label, ion-input {
  font-family: unset !important;
}

.open_sans__font {
  font-family: "Open Sans", sans-serif !important;
}


.manrope__font {
  font-family: 'Manrope', sans-serif;
}

.sans-font {
  font-family: 'Open Sans', sans-serif;
}

.roboto-font {
  font-family: 'Roboto', sans-serif;
}

//Remove input box increase arrow
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.primary {
  color: var(--primary-text);
}

.primary-border {
  border-color: var(--secondary-color) !important;
}

.m-10 {
  margin-bottom: 15px;
}

.icon_s {
  font-size: 29px;
}

.alternative {
  color: var(--alternative);
}


.app_desc{

  font-size: 20px !important;
    color: #fff!important;
    padding-top: 10px;
    font-weight: 500;

  *{
    font-size: 20px !important;
    color: #fff!important;
    padding-top: 10px;
    font-weight: 500;   
  }
}

.bg-white {
  background-color: #fff;
}

// ion-item:hover,ion-button:hover{
//   --ion-item-color : var(--secondary-color);
// }
// ion-button:hover{
//   --background : var(--secondary-light-bg );
// }
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.back_image {
  //  background-image: url("/assets/districo/footer-bg.svg");
  //    background-size: cover;
  //    background-repeat: no-repeat;
  //    background-color: #ddd;
  //    background: red;
}

.cart-buttons,
.image,
.main-image {
  user-select: none;
  -webkit-user-select: none;
}




ion-fab-button {

  --background: var(--secondary-color);

  ion-icon {
    color: var(--white);
  }
}

ion-ripple-effect {
  color: var(--primary-color);
}

ion-segment-button {
  --indicator-color: var(--primary-color) !important;
  min-width: unset
}

ion-tab-button {
  // --color: var(--ion-color-medium);
  --color-selected: var(--secondary-color);
  --color: var(--secondary-color);

  &.tab-selected {

    // background-color: var(--primary-color);
    ion-icon {
      // font-size: 32px;
      // color: var(--secondary-color);
      color: var(--secondary-color);
    }

    ion-label {
      font-weight: 600;
      color: var(--secondary-color);
    }
  }
}

ion-refresher-content {
  color: white !important;
}

ion-slides {
  --bullet-background: white;
  --bullet-background-active: var(--secondary-color) !important;
}

ion-checkbox,
ion-radio {
  --background-checked: var(--secondary-color);
  --border-color-checked: transparent;
  --border-color: var(--secondary-text);
  --color-checked: var(--secondary-color);
  --border-width: 1px;
}

.main-width {
  width: 90%;
    max-width: 1360px;
    margin: 0 auto;
    min-height: 100%;
  // width: 90%;
  // max-width: 90%;
  // margin: 0 auto;
  // min-height: 100%;

}

.main-width-full {
  width: 100%;
  max-width: 100%;

}

.main-width2 {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  min-height: 100%;
}

.sticky__header{
  position: sticky;
  top: 0;
  z-index: 99;
  background: #fff;
}

a {
  text-decoration: none;
  color: var(--primary-text);
}

.m-0 {
  margin: 0;
}

.m-b-15 {
  margin-bottom: 15px;
}

.p-0 {
  padding: 0;
}

.p-5 {
  padding: 5px;
}

.m-5 {
  margin: 5px;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.pr-10 {
  padding-right: 10px;
}

.p-20 {
  padding: 20px;
}

.mouse {
  cursor: pointer;
}

.fnt-14 {
  font-size: 14px;
}

.fnt-18 {
  font-size: 18px;
}

.fnt-15 {
  font-size: 15px;
}

.fnt-13 {
  font-size: 13px;
}

.fnt-12 {
  font-size: 12px;
}

.fnt-11 {
  font-size: 11px;
}

.fnt-10 {
  font-size: 10px;
}

.fnt-16 {
  font-size: 16px;
}
.fnt-18 {
  font-size: 18px;
}

.fnt-500 {
  font-weight: 500;
}
.fnt-700 {
  font-weight: 700;
}

.fnt-300 {
  font-weight: 300;
}

.fnt-20 {
  font-size: 20px;
  font-weight: 600;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.mb-5 {
  margin-bottom: 5px;
}

.py-20 {
  padding: 20px 0;
}

.floatRight{
  float: right;
}

// .m-30{
//     padding: 30px 0;
// }

.user-select {
  user-select: none;
  -webkit-user-select: none;
}

.fnt-600 {
  font-weight: 600;
}
.fnt-700 {
  font-weight: 700;
}

.star {
  color: #fec650;
  font-weight: 600;
}

.prices {

  span,
  p {
    color: var(--secondary-color);
    font-weight: 700;
    font-family: "Open Sans", sans-serif !important;
  }
}

.close-icon {
  font-size: 20px;
  user-select: none;
}

.close-icon:hover {
  color: red;
}

.flex-3 {
  flex: 0 0 33.333%;
}

.flex-4 {
  flex: 0 0 25%;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 15px;
}

.gap-20 {
  gap: 20px;
}

.gap-2 {
  gap: 2px;
}

.gap-50 {
  gap: 50px;
}

.br-3 {
  border-radius: 3px;
}

.br-5 {
  border-radius: 5px;
}

ion-skeleton-text {
  border-radius: 3px;
}

.webkit-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: var(--line);
  -webkit-box-orient: vertical;
}

.bold_name{
  font-size: 16px !important;
  font-weight: 600 !important;
}

.bold_name-15{
  font-size: 15px !important;
  font-weight: 600 !important;
}

.bold_name-15-500{
  font-size: 15px !important;
  font-weight: 500 !important;
}

.bold_name-14{
  font-size: 14px !important;
  font-weight: 600 !important;
}

.bold_name-12{
  font-size: 12px !important;
  font-weight: 600 !important;
}

.medium_name{
  font-size: 14px !important;
  font-weight: 500 !important;
}

.medium_name-16 {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.medium_name-14-400{
  font-size: 14px !important;
  font-weight: 400 !important;
}


.medium_name-13-400{
  font-size: 14px !important;
  font-weight: 400 !important;
}

.medium_name-13{
  font-size: 13px !important;
  font-weight: 400 !important;
}


.small_name-600{
  font-size: 12px !important;
  font-weight: 600 !important;
}

.small_name{
  font-size: 12px !important;
  font-weight: 500 !important;
}


.d__flex {
  display: flex;
  align-items: center;
}


.d__column {
  display: flex;
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.flex-w {
  flex-wrap: wrap;
}

.just-c {
  justify-content: center;
}

.just-s {
  justify-content: space-between;
}

.just-end {
  justify-content: flex-end;
}

.just-a {
  justify-content: space-around;
}

.just-st {
  justify-content: start;
}

.flex-100{
  flex: 0 0 100%;
}

.txt-r {
  text-align: right;
}

.txt-l {
  text-align: left;
}

.txt-c {
  text-align: center;
}

.ov {
  overflow: visible;
}

.ov-x {
  overflow-x: auto;
}

.ov-y {
  overflow-y: auto;
  height: 100vh;
}

.mx-10 {
  margin: 0 10px;
}

.my-5 {
  margin: 5px 0;
}

.my-2 {
  margin: 2px 0;
}

img {
  user-select: none;
}

.my-10 {
  margin: 10px 0;
}

.mx-15 {
  margin: 0 15px;
}

.my-15 {
  margin: 15px 0;
}

.mb-8 {
  margin-bottom: 8px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.py-5 {
  padding: 5px 0;
}

.px-b-10 {
  padding: 0 10px 10px 10px;
}

.py-15 {
  padding: 15px 0;
}

.py-10 {
  padding: 10px 0;
}

.px-15 {
  padding: 0 15px
}

.px-10 {
  padding: 0 10px;
}

.l-t {
  text-decoration: line-through;
}

.nw {
  white-space: nowrap;
}

.mini-100 {
  //min-height: 450px;
}

.mb-15 {
  margin-bottom: 15px;
}
.mb-55{
 margin-bottom: 55px; 
}

.mr-5 {
  margin-right: 5px;
}

.mt0{
  margin-top: 0px;
}

.mt-5 {
  margin-top: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.w-100 {
  width: 100% !important;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-25 {
  width: 25%;
}

.w-20 {
  width: 20%;
}

.h-100 {
  height: 100%;
}

.mt-15 {
  margin-top: 15px;
}

.bg-white {
  background-color: #fff;
}

.active-heart {
  color: red !important;
}

.ripple-parent {
  position: relative;
  overflow: hidden;
}

.position-relative {
  position: relative;
}

.border {
  border: 1px solid;
  color: var(--border);
  // opacity: 70%;
}

.Breadcrumb {
  font-size: 13px;
}

.align-c{
  align-items: center;
}

.Bread-crumb {
  display: flex;
  align-items: center;
  font-size: 13px;

  li {
    font-size: 13px;
    span{
      font-size: 13px;
    }
  }

  a,
  ion-icon {
    white-space: nowrap;
    text-overflow: ellipsis;
    text-decoration: none;
    color: var(--secondary-text);
    font-size: 13px;
  }

  a:hover,
  .active-bread {
    color: var(--primary-text);
  }
}

.icon.hydrated {
  font-size: 32px;
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
    margin: 0;
     span{
      font-size: 13px;
    }
  }
}

.border-b {
  border-bottom: 1px solid var(--border);
}

.border-t {
  border-top: 1px solid var(--border);
}

ion-header {
  &.header-md:after {
    background: none;
  }

  ion-toolbar {
    --min-height: 50px;
    --background: #ffff;
    background-color: #fff;
    border-bottom: 1px solid #f1f1f1;

    ion-icon,
    ion-back-button,
    ion-title,
    p {
      color: var(--primary-text);
    }
  }

  // .back-btn{
  //     font-size: 24px;
  //     margin-left: 10px;
  //     color:  #70757a;;
  // }
  // border-bottom:  1px solid #dadce063;
  // box-shadow: 0 3px 6px 0 rgb(40 47 54 / 10%);
}

// ion-item{
//   --background : #e8e8e833 !important;
// }
// ion-item:hover{
//   --ion-item-color : var(--primary-color);
// }
ion-input,
ion-select {
  height: 38px;
}

.textarea-wrapper.sc-ion-textarea-md {
  height: 100%;

  textarea {
    height: 100% !important;
    padding: 5px 7px;
    font-size: 14px;
  }
}


.account_settings{
  .native-input[disabled].sc-ion-input-md{
  opacity:1;
}

.native-textarea[disabled].sc-ion-textarea-md{
 opacity:1; 
}
}

@media (min-width: 768px) {
  .yorcart_wish_web {
    margin-top: 35%;
  }

  .add-image-modal {
    --height: 80% !important;
    --width: 70%;
    --border-radius: 5px;

    .ion-page {
      display: unset !important;
      overflow: visible !important;
    }
  }

  .add-attribute-modal {
    --height: 80% !important;
    --width: 45%;
    --border-radius: 5px;

    .ion-page {
      display: unset !important;
      overflow: auto !important;
      position: absolute !important;
    }
  }

  .attribute-edit-popup {
    --height: 80% !important;
    --width: 70%;
    --border-radius: 5px;

    .ion-page {
      display: unset !important;
      overflow: visible !important;
    }
  }

  .btn-lg {
    height: 50px;
    --color: var(--white) !important;
    color: var(--white) !important;
    background-color: var(--secondary-color);
    border-radius: 3px;
  }


  .customToastClass {
    color: #fff;
    left: 40%;
    width: 23%;
    --border-radius: 40px;
    text-align: center;
    transition: 0.3s;
  }
}

@media (max-width: 768px) {


  .add-attribute-modal {
    .ion-page {
      display: unset !important;
      overflow: auto !important;
      position: absolute !important;
    }
  }

  .customToastClass {
    color: #fff;
    left: 10%;
    width: 80%;
    --border-radius: 40px;
    text-align: center;
    transition: 0.3s;
  }

  // your cart -change button 

  .small__heading_change {
    background: #f1f1f1;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #dbdbdb;
  }

  ion-input,
  ion-select {
    // height: 38px; 
    font-size: 13px;
  }

  ion-button {
    //height: 42px;
    font-size: 13px;
    font-weight: 500;
  }

  .main-width {
    width: 100%;
    max-width: 100%;
    margin: 0;
    display: block;
  }

  .attribute-popup,
  .quick-view-popup {
    --height: 55% !important;
    --width: 85%;
    --border-radius: 5px;
  }

  .pincode-popup {
    padding: 200px 0 0;
    --border-radius: 10px 10px 0 0;
  }


  .oops-icon {
    position: absolute;
    left: 0;
    right: 0;
    top: 20%;
    margin-left: auto;
    margin-right: auto;
    width: 150px;

    ion-icon {
      font-size: 150px;
    }
  }

  // .cat-price {
  //   font-size: 14px;
  // }
  .cat-title {
    font-weight: 500;
    white-space: nowrap;
  }
}

.actionsheet-popup {
  --height: 100% !important;
  padding-top: 199px !important;
  border-radius: 20px 20px 0 0 !important;

  .ion-page {
    display: unset !important;
  }
}

.attribute_change_mobile {
  --height: 100% !important;
  padding-top: 300px !important;
  --border-radius: 20px 20px 0 0 !important;
  .ion-page {
    display: unset !important;
    // overflow: auto;
    position: absolute !important;
  }
}

.attribute_change_web {
  --height: 65% !important;
  --width: 35% !important;
  --border-radius: 10px !important;
  .ion-page {
    display: unset !important;
    overflow: auto;
    position: absolute !important;
  }
}

.store-actionSheet{
  --height: 100% !important;
  // padding-top: 280px !important;
  padding-top: 100px !important;
  --border-radius: 20px 20px 0 0 !important;
  // margin-bottom: 15px;
  .ion-page {
    // display: unset !important;
  }
}

@media (max-width: 768px) {
  .video_popup {
    --height: 300px;
    --width: 96%;
    margin: 0 auto;
    --overflow: visible;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.9);
    --ion-backdrop-color: rgb(44, 57, 74);

    .ion-page {
      overflow: visible !important;
    }
  }
}

@media screen and (min-width: 1024px) {

  // .mr_r {
  //   margin-left: -4px;
  // }
  .mpl-15 {
    margin-left: -15px;
  }

  .table__items {
    * {
      font-size: 14px;
    }
  }

  .table__header {
    padding: 10px 0;
    // background-color: var(--primary-color);
    background-color: #f3f4f5;

    * {
      font-size: 15px;
      font-weight: 600;
    }
  }
}

@media (min-width: 768px) {
  .video_popup {
    --height: 600px !important;
    --width: 60% !important;
    --overflow: visible;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.9);
    --ion-backdrop-color: rgb(44, 57, 74);

    .ion-page {
      overflow: visible !important;
    }
  }

  .segment {
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: sticky;
    top: 0;
    background: #fff !important;
    z-index: 1;

    .btn {
      // width: 50%;
      width: 100%;
      text-align: center;
      padding-bottom: 8px;
      font-weight: 500;
      color: var(--secondary-text);
      padding: 10px;
      border-bottom: 3px solid;
      border-color: transparent;
    }

    .active {
      border-color: var(--secondary-color);
      color: var(--primary-text);
    }
  }
}

@media only screen and (min-width: 768px) {

  // .sc-ion-modal-md-h {
  //   --width: 490px !important;
  //   --height: 565px !important;
  // }
  ion-menu {
    --min-width: 360px;
  }

  .make-return-popup {
    --width: 650px !important;
    --height: 450px !important;
    --border-radius: 10px;
  }

  .add-popup {
    --width: 550px !important;
    --height: 600px !important;
    --border-radius: 10px;
  }

  .sharing-popup {
    --width: 500px;
    --height: 290px;
    --backdrop-opacity: 30% !important;
  }

  .order-detail-popup {
    --width: 1000px !important;
    --height: 600px !important;
    --border-radius: 11px;
  }

  .quick-view-popup {
    --width: 1060px !important;
    --height: 580px !important;
    --border-radius: 11px;
  }

  .auth-popup {
    // --width: 495px !important;
    // --height: 510px !important;
    --width: 1000px !important;
    --height: 575px !important;
    --border-radius: 11px;
  }

  .address-popup {
    --width: 800px !important;
    // --width: 550px !important;
    --height: 525px !important;
    --border-radius: 5px !important;
  }

  .address-popup-map {
    --width: 800px !important;
    // --width: 550px !important;
    --height: 605px !important;
    --border-radius: 5px !important;
  }

  .attribute-popup {
    --width: 420px !important;
    --height: 453px !important;
    --border-radius: 5px !important;
  }

  .review-poup {
    // --height: 550px !important;
    --height: 600px !important;
    --width: 500px !important;
    --padding-left: 750px !important;
    --padding-top: 135px !important;

    // --backdrop-opacity: var(--ion-backdrop-opacity, 0.3) !important;
    .sc-ion-modal-md-h {
      padding-left: 750px !important;
      padding-top: 135px !important;
    }

    .ion-page {
      display: unset !important;
    }
  }

  .feedback-poup {
    --height: 440px !important;
    --width: 500px !important;
    --padding-left: 750px !important;
    --padding-top: 135px !important;

    // --backdrop-opacity: var(--ion-backdrop-opacity, 0.3) !important;
    .sc-ion-modal-md-h {
      padding-left: 750px !important;
      padding-top: 135px !important;
    }

    .ion-page {
      display: unset !important;
    }
  }


  .forget-popup{
    --width: 480px !important;
    --height: 300px !important;
    --border-radius: 5px !important;
  }
}

ion-spinner {
  height: 17px;
  width: 27px;
  // margin-right: 3px;
}


.review-poup {
  .ion-page {
    display: unset !important;
  }
}

@media only screen and (max-width: 768px) {
  .message-reply-popup {
    padding: 105px 0 0 0;
    --border-radius: 10px 10px 0 0;
  }
// * {
//     font-size: 14px;
//   }
  .product_details div li{
    font-size: 14px;
  }
}

  
.reason_popup{
    --width: 1000px !important;
    padding-top: 240px;
    --border-radius: 25px 25px 0 0;
    margin-top: 50px;
    border-radius: 10px;
  }



ion-button,
ion-badge {
  background: var(--secondary-color);
  color: var(--white);
  border-radius: 20px;
}

// ion-badge{
//   background: var(--secondary-color);
// }
.p5 {
  padding: 5px;
}

.mb5 {
  margin-bottom: 5px;
}

.bg-gray {
  background: var(--gray-bg);
}

.gray_cr{
  color: var(--gray-color);
}

.green_clr{
  color: var(--alternative);
}

.main-width1 {
  width: 95%;
  margin: 0 auto;
  padding: 20px 0;
}

.main-width-full {
  width: 100%;
}

// .form-input[readonly="true"], .form-input[ng-reflect-readonly=true], 
// .form-input input[readonly="true"], .form-input input[readonly]  {
//     background: #ddd;
// }
// .no-bg {
//   --background-hover: transparent !important;
//   --background-focused: transparent !important;
//   .box-label {
//     color: #000 !important;
//   }
// }
.focused {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid var(--border) !important;
  mask-border-width: 2px;
  transition-duration: .15s;
}

.head-container {
  margin: auto;
  padding: 0 5px;

  img {
    height: 60px;
  }

  .userProfileIcon {
    float: right;
    padding: 5px;
  }
}

.head-title {
  font-size: 18px;
  padding-inline: 10px;
}

.container-cls {
  margin: auto;
  padding: 0 15px;
}

// @media screen and (min-width: 992px) {
.page_section {
  margin: 0 auto;
  // max-width: 1366px;
  padding: 0 60px 0 60px;
}

.text-center {
  text-align: center;
}

.white-text {
  color: var(--white);
}

.free__item {
  position: absolute;
  left: -19px;
  transform: rotate(-45deg);
  top: 5px;
  background: var(--alternative);
  width: 80px;
  padding: 1px 10px 1px 21px;

  .free__item__text {
    color: var(--white);
    font-size: 11px;
  }
}

.oops-icon {
  // position: absolute; 
  // left: 0; 
  // right: 0; 
  // top:20%;
  // margin-left: auto; 
  // margin-right: auto; 
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 350px;

  ion-icon {
    font-size: 350px;
  }
}




.mobile__unique__category {
  // background: #fff;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  padding: 5px 10px;
  gap: 10px;
  // border-bottom: 1px solid var(--border);
  // box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
  // margin-bottom: 5px;

  .category__item {
    // flex: 0 0 20%;
    padding: 0;

    .category__img {
      border: 1px solid var(--border);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      width: 44px;

      // background: var(--secondary-color);
      img {
        background: #fff;
        height: 20px;
        // height: 40px;
      }
    }

    .active-category-name {
      color: var(--white);
      background: var(--secondary-color) !important;
    }


    .category__name {
      // text-align: center;
      // font-size: 13px;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      overflow: hidden;
      // max-width: 60px;
      white-space: nowrap;
      margin: 3px;
      white-space: nowrap;
      background: #e3e3e366;
      padding: 5px 10px;
      border-radius: 5px;
      font-size: 16px;
    }
  }
}

@media screen and (min-width:768px) {
  img.ng-lazyloaded {
    animation: fadein .7s;
    transition: opacity .4s ease-in-out, transform .4s ease-in-out;
    // -webkit-animation-name: fadeInLeft;
    // animation-name: fadeInLeft;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
    // width: 50%;
  }

  to {
    opacity: 1;
    // width: 100%;
  }
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    //  width: 50%;
    //  -webkit-transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    //  width: 100%;
    //  -webkit-transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    //  width: 50%;
    //  transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    //  width: 100%;
    //  transform: translateX(0);
  }
}

.disabled {
  opacity: 0.7;
}

.tostifyy {
  position: fixed;
  z-index: 9999;
  -webkit-transform: translate3d(0, 0, 999px);
  transform: translate3d(0, 0, 999px);
  transition: transform 0.2s ease 0s, opacity 0.2s ease 0s;
  box-sizing: border-box;

  .tostify {
    // height: 50px;
    margin: 20px 0;
    transition: transform 0.2s ease 0s, opacity 0.2s ease 0s;
    transform: translateX(0px);
    opacity: 1;
    padding: 10px 10px;
    border-radius: 3px;

    // box-shadow:  1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
    .progress {
      animation-duration: 1500ms;
      animation-play-state: paused;
      opacity: 1;
    }

    ion-icon {
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 19px;
      color: #fff;
      cursor: pointer;
    }

    .image {
      height: 70px;
      width: 70px;
      min-width: 80px;
      margin: 4px;
      flex: 0 0 calc(25% - 8px);

      img {
        height: 70px;
      }
    }

    .text_grid {
      display: flex;
      flex-direction: column;
      flex: 0 0 70%;

      .title {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}

.success__alert {
  width: 380px;
  bottom: 50px;
  right: 50px;

  .tostify {
    background-color: #3eb77e;
    // background: var(--yellow-color);
  }

  .success-txt {
    font-size: 13px;
    background:#3eb77e;
    // background: var(--yellow-color);
    padding: 2px 5px;
    border-radius: 6px;
    color: #fff;

    .sucss {
      // padding-left: 15px;
      color: var(--white);
      font-weight: 600;
      text-decoration: underline
    }

    span {
      color: var(--white);
    }
  }
}

.tostify__alert {
  width: 220px;
  bottom: 0;
  right: 50px;

  .tostify {
    background-color: red;

    span {
      font-size: 13px;
      color: #fff;
    }
  }
}

@media screen and (max-width:768px) {
  .tostify__alert {
    bottom: 65px !important;
    right: 80px !important;
  }
}

.shaking {
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

// ion-icon {
//     pointer-events: none;
//   }

// "outputPath": "dist/app/browser",
.oops-icon1 {
  text-align: center;
  width: 100%;
  height: calc(100% - 133px);
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  ion-icon {
    font-size: 160px;
    text-align: center;
    position: relative;
  }

  img {
    height: 80px;
    text-align: center;
    position: relative;
    margin-bottom: 10px;
  }

  .oops-text {
    text-align: center;
    font-weight: 700;
    font-size: 16px;
  }
}
.oops__{
  img{
    height: 80px !important;
  }
}

.sub_now {
  margin: 5px 0 0 0;
  background: var(--secondary-color) !important;
  // color: var(--primary-color);
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
}

@media screen and (max-width:768px) {
  .oops-icon1 {
    height: calc(100% - 220px) !important;
    margin-top: 35%;

    img {
      height: 54px !important;
      text-align: center;
      position: relative;
      // margin-bottom: 20px;
    }

    .oops-text {
      text-align: center;
      font-weight: 600;
      font-size: 14px;
      margin: 5px 0;
     }
      p{
        font-size: 14px;
      }
  }

  .oops__icon1 {

    // height: calc(100% - 220px) !important;
    // margin-top: 35%;
    img {
      height: 120px !important;
      text-align: center;
      position: relative;
    }
  }

  .sub_now {
    font-size: 12px !important;
  }
}

.location__warning {
  gap: 10px;
  background: #ff00001f;
  padding: 6px;
  color: red;

  ion-icon,
  p {
    color: red;
  }
}

.errors {
  font-size: 11px;
  color: red;
  margin-top: -5px;
  margin-bottom: 5px;
}

.error {
  font-size: 10px;
  color: red;
  background: #ff000014;
  padding-left: 10px;
}

.action-button {
  text-transform: capitalize;
}

.txt__upper {
  text-transform: uppercase;
}

.txt__capital {
  text-transform: capitalize;
}

@media screen and (max-width:768px) {
  .action-button {
    width: 85%;
    margin: 0 auto;
  }
}

@media screen and (min-width:768px) {
  .action-button {
    font-size: 16px;
    font-weight: 500;
    border-radius: 3px;
    height: 50px;
    margin: 0 10px;
  }

  .myAccount-titles {
    // border-bottom-width: 1px;
    // border-bottom-style: solid;
    // border-bottom-color: var(--border);
    border-bottom: 1px solid var(--border);
    margin: 0;
    padding: 12px;
    font-size: 16px;
  }
}

form {
  .input__title {
    font-size: 13px;
    color: #000;
    opacity: 0.7;
    margin: 0;
    font-weight: 500;
  }

  .custom-input {
    width: 100%;
    margin: 7px 0;
    border: 1px solid;
    border-color: var(--border);
    border-radius: 3px;
    font-size: 14px;

    ion-select {
      padding-right: 10px;
    }
  }

  .divider {
    // margin: 20px 0;
    border-bottom: 1px solid var(--border);
  }
}

.focus:focus {
  outline: 1px solid transparent;
  outline-offset: 2px;
}

ion-button,
ion-badge {
  background: var(--secondary-color);
  color: var(--white);
  border-radius: 5px;
}

.location-modal {
  --width: 530px;
  --height: 400px;
  padding-top: 85px;
  align-items: start;
  // padding-right: 515px;
  padding-right: 400px;
  --border-radius: 3px !important;
}

.location-modal-locations {
  --width: 530px;
  --height: 241px;
  padding-top: 85px;
  align-items: start;
  padding-right: 460px;
  // padding-left: 515px;
  --border-radius: 3px !important;
  --overflow: visible;

  .ion-page {
    overflow: visible !important;
  }
}

// .store-web {
//   --width: 300px;
//   --height: 185px;
//   padding-top: 90px;
//   align-items: start;
//   padding-right: 215px;
//   --border-radius: 3px !important;
//   --overflow: visible;

//   .ion-page {
//     overflow: visible !important;
//   }
// }

.store-web {
  justify-content: start;
  --width: 23%;
  --height: 100%;
  // margin-top: 60px;
  --border-radius: 3px !important;
  --overflow: visible;
  .ion-page {
    overflow: visible !important;
  }
}

.order-reason-web {
  justify-content: end;
  --width: 23%;
  --height: 100%;
  // margin-top: 60px;
  --border-radius: 3px !important;
  --overflow: visible;
  .ion-page {
    overflow: visible !important;
  }
}




// .body{
//     background: red;
// }

// body{
//     background: red;
// }

// body{
//        background-image: url('/assets/districo/footer-bg.svg');
//    }
.page-builders {
  //    background-image: url('/assets/districo/footer-bg.svg');
}

// .profile__page{
//     .ion-page{
//         height: 100vh;
//     }
// }

.external-view {
  width: 600px;
  height: 600px;
  margin-left: auto;
  margin-right: auto;
}


.custom-img-zoom-lens {
  position: absolute;
  border: 1px solid aqua;
  border-radius: 10px;
  /*set the size of the lens:*/
  background-color: white;
  opacity: 0.2;
  width: 100px;
  height: 100px;
}

.custom-img-zoom-result {
  position: absolute;
  top: 0;
  // margin-left: 10px;
  // border: 2px solid blue;
  border-radius: 20px;
  /*set the size of the result div:*/
  width: 500px;
  height: 500px;
  right: -515px !important;
}

.custom-img-container {
  position: relative;
  // display: inline-block;
  // border: 2px solid black;
}

.custom-img-container img {
  vertical-align: middle;
}


.ytp-popup.ytp-contextmenu {
  display: none !important;
}

.html5-video-player:not(.ytp-big-mode) .ytp-chrome-top .ytp-copylink-button {
  display: none !important;
}

.ytp-chrome-top,
.ytp-chrome-bottom {
  display: none !important;
}

.ytp-hide-controls:not(.ytp-mweb-player) .ytp-watermark {
  display: none !important;
}

.ytp-chrome-top.ytp-show-cards-title {
  display: none !important;
}


.h-100 {
  min-height: 100vh;
}

.desktop-header {
  transition: 0.5s;
  // box-shadow: rgb(0 0 0 / 8%) 0px 5px 6px;
  z-index: 999;
}

.popover-wide {
  padding: 10px;
  font-size: 14px;
}

.about_restaurant {
  h1 {
    font-size: 18px;
    margin: 0;
  }
}

.Fields-9,
.Fields-7,
.Fields-6,
.Fields-5,
.Fields-2 {
  .table__data {
    div {
      ion-input {
        border-radius: 5px;
        border: 1px solid var(--border);
        width: 80%;
      }
    }
  }
}

.Fields-9 {
  width: 100%;

  .table__data {

    div:nth-child(1) {
      width: 4%;
    }

    div:nth-child(2) {
      width: 12%;
    }

    div:nth-child(3) {
      width: 13%;
    }

    div:nth-child(4) {
      width: 11%;
    }

    div:nth-child(5) {
      width: 14%;
    }

    div:nth-child(6) {
      width: 12%;
    }

    div:nth-child(7) {
      width: 11%;
    }

    div:nth-child(8) {
      width: 13%;
    }

    div:nth-child(9) {
      width: 10%;
    }
  }
}

.Fields-7 {
  width: 100%;

  .table__data {
    div:nth-child(1) {
      width: 4%;
    }

    div:nth-child(2) {
      width: 16%;
    }

    div:nth-child(3) {
      width: 16%;
    }

    div:nth-child(4) {
      width: 17%;
    }

    div:nth-child(5) {
      width: 17%;
    }

    div:nth-child(6) {
      width: 15%;
    }

    div:nth-child(7) {
      width: 15%;
    }
  }
}

.Fields-6 {
  width: 100%;

  .table__data {

    div:nth-child(1) {
      width: 5%;
    }

    div:nth-child(2) {
      width: 16%;
    }

    div:nth-child(3) {
      width: 35%;
    }

    div:nth-child(4) {
      width: 15%;
    }

    div:nth-child(5) {
      width: 15%;
    }

    div:nth-child(6) {
      width: 15%;
    }
  }
}

.Fields-5 {
  width: 100%;

  .table__data {

    div:nth-child(1) {
      width: 5%;
    }

    div:nth-child(2) {
      width: 20%;
    }

    div:nth-child(3) {
      width: 20%;
    }

    div:nth-child(4) {
      width: 25%;
    }

    div:nth-child(5) {
      width: 30%;
    }
  }
}

.Fields-2 {
  width: 100%;

  .table__data {
    div:nth-child(1) {
      padding-left: 10px;
      width: 70%;
    }

    div:nth-child(2) {
      width: 30%;
    }
  }
}

//ngselect search with dropdown

.ng-select .ng-select-container{
  border-color: var(--border-col)!important;
  box-shadow: none!important;
}

.ng-select.ng-select-single .ng-select-container {
  border: unset;
}

.ng-dropdown-panel .scroll-host{
  &::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
  }
  &::-webkit-scrollbar
  {
    width: 6px;
    background-color: #F5F5F5;
  }
  &::-webkit-scrollbar-thumb
  { 
    border-radius: 14px;
    height: 30px;
    background-color: var(--primary-color);
  }
}

.ng-dropdown-panel{
  position: relative;
  border: 1px solid var(--border-col)!important;
  // box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
  box-shadow: 0 0.5rem 1rem #0000002d;
  text-align: start;
  .ng-dropdown-header{
      border-bottom: none;
      margin-bottom: 5px;
      input:focus {
          outline: unset!important;
      }
      .custome_input{
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        padding: 4px 6px;
        border-radius: 5px;
      }
  }
  // &:before{
  //   position: absolute;
  //   top: -10px;
  //   left:50%;
  //   margin-left: -10px;
  //   content:"";
  //   display:block;
  //   border-left: 10px solid transparent;
  //   border-right: 10px solid transparent;
  //   border-bottom: 10px solid rgb(255, 0, 0); 
  // }
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value{
  display: flex!important;
  flex-direction: row-reverse!important;
  background-color: #69AAFB33!important;
}

.image-div_ .attached .value_ {
  height: unset !important;
  display: -webkit-box !important;
}

.image-div_{

  input{
    display: none;
  }

  .browser{
    border: 1px dashed var(--primary-bg);
    border-radius: 5px;
    gap: 5px;
    margin: 10px 0;
     .name{
       justify-content: space-between;
       padding: 0 8px;
       height: 45px;
       border-right: 1px solid var(--border-col);
       align-items: center;
        ion-icon{
          font-size: 15px;
          padding: 0 0 0 10px;
        }
     }
     .value_{
      font-size: 14px;
      color: #000;
      font-weight: 600;
      height: 45px;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0 5px;
     }
     .value_:hover {
        text-decoration: underline;
     }
  }

  .value_div{
    width: 80%;
    justify-content: space-between;
    .close_icon{
      cursor: pointer;
      background: #000;
      color: #fff;
      border-radius: 50%;
      font-size: 15px;
      padding: 1px;
      margin: 0 5px 0 0;
    }
  }

  .attached{
    .value_{
      height: unset !important;
      display: -webkit-box !important;
     }
  }
}


.attribute_d{
  .attribute,span{
    font-size: 12px;
    font-weight: 500;
    color: var(--gray-color);
  }
}

.sold_by_d{
  font-size: 12px;
  font-weight: 500;
  color: var(--gray-color);
  .sold_by,.more_{
    font-size: 12px;
    font-weight: 500;
    text-transform: capitalize;
    color: var(--secondary-color);
  }
  .more_{
    color: var(--primary-bg) !important;
  }
}

.product_details{
  padding: 5px 10px !important;
  p{
    margin: 0 !important;
    font-size: 13px !important;
    font-weight: 400 !important;
  }
}

.product-desc{
  .desc{
    p{
      font-size: 14px !important;
      font-weight: 400 !important;
    }
  }
}


.ng-untouched .ng-has-value .ng-placeholder {
   display: unset;
   color: #999999cf;
   font-size: 14px;
   font-weight: 400;
   opacity: 0.9;
}

.ng-touched .ng-has-value .ng-placeholder {
  display: none !important;
}

.product-desc{
  div,p,span,li{
    font-size: 14px;
    font-weight: 400;
  }
}

.display_none{
  display: none;
}